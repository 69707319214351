import React from "react"
import Layout from "../../components/layout/layout"
import { Link } from "gatsby"
export default class Contact extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <Layout>
        <div className="">
          <h3>Message from the Receiver</h3>
          <p>February 16, 2017</p>
          <p>
            <strong>
              Re: Oxton Senior Living, LLC Receivership (Case
              2_17-cv-00393-ES-SCM)
            </strong>
          </p>
          <p>Dear interested party:</p>
          <p>
            As you may be aware, Healthcare Management Partners, LLC (“HMP”) and
            I have been appointed as receiver (the “Receiver”) over 8 assisted
            living facilities (“the Facilities”) across the states of Georgia
            and Alabama.  As the Receiver, the Court has charged me with
            controlling and administering the Facilities; in other words, I am
            now responsible for overseeing the entire operation of these
            assisted living facilities.
          </p>
          <p>
            I have twenty-six years of experience in the healthcare industry and
            have served as a hospital CFO, a nursing home CFO, a chief
            restructuring officer (“CRO”) of an assisted living facility,
            director of reimbursement, Medicare auditor, Medicare cost report
            preparer, forensic accountant, compliance consultant, financial
            auditor, and financial advisor.  I have also previously served as a
            court-appointed examiner over a nursing home, a court-appointed
            chapter 11 trustee over a nursing home, and a receiver over numerous
            nursing homes.  As a result, federal and state courts in Georgia,
            Tennessee, and Alabama have reviewed my qualifications and
            determined that I am qualified to control and administer healthcare
            facilities under the supervision of the respective court.  Since
            2006, I have served as a Managing Director of HMP.  HMP is a
            turnaround and consulting firm that specializes in assisting
            healthcare organizations experiencing current, or anticipated,
            financial challenges navigate their way to positive outcomes.  HMP’s
            executives have led organizations of all kinds across the continuum
            of care.  You can learn more about me and HMP at{" "}
            <Link target="_blank" to="http://www.hcmpllc.com">
              www.hcmpllc.com.
            </Link>
          </p>
          <p>
            Over the past few months, I have had the pleasure of meeting and
            visiting with several employees and residents to hear first-hand the
            things that are important to them.  I have also met with the
            Facilities’ administrative staff and have full confidence in their
            ability to maintain a high level of care for the residents of their
            Facilities and to operate their respective Facilities responsibly
            with efficiency and integrity.
          </p>
          <p>
            If you have any questions, I may be via email at{" "}
            <a href="mailto:oxton.receivership@hcmpllc.com">
              oxton.receivership@hcmpllc.com
            </a>
          </p>
          <p>Very truly yours,</p>
          <p>Derek Pierce</p>
        </div>
      </Layout>
    )
  }
}
